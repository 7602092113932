module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"narrativemon","accessToken":"MC5YOVV0ekJBQUFDSUFXVW5i.77-977-977-9fQrvv70oI--_vQLvv70EAVVg77-977-9EjhgDTIz77-9DQTvv708eO-_vS0V","schemas":{"post":{"Main":{"uid":{"type":"UID","config":{"label":"uid"}},"date":{"type":"Timestamp","config":{"label":"date","placeholder":"date"}},"tag":{"type":"Text","config":{"label":"tag","placeholder":"tag"}},"cover_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"cover_image"}},"title":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"title"}},"subtitle":{"type":"StructuredText","config":{"single":"heading4","label":"subtitle","placeholder":"subtitle"}},"body":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","allowTargetBlank":true,"label":"body","placeholder":"body"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"narrative-monopoly-podcast","short_name":"narrative","start_url":"/","background_color":"#e6ffea","theme_color":"#e6ffea","display":"minimal-ui","icon":"src/images/final_cover.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"04e5dde97f46a9b0720da034480df285"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
